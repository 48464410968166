<template>
  <BaseForm
    :title="$route.meta.title"
    :loading="loading"
    @submit="handleSubmit"
  >
    <template v-slot:content>
      <v-row>
        <!-- NAME FIELD -->
        <v-col cols="6" md="6">
          <ValidationProvider
            name="Días"
            rules="required|numeric|min:0"
            v-slot="{ errors }"
          >
            <v-text-field
              type="number"
              label="Días del Periodo"
              hint="Días del Periodo"
              v-model="displayPeriod.days"
              autofocus
              dense
              outlined
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="6" md="6">
          <ValidationProvider
            name="Descripción"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              label="Descripción"
              hint="Descripción"
              v-model="displayPeriod.description"
              autofocus
              dense
              outlined
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="6">
          <ValidationProvider
            name="Posición del periodo"
            rules="required|numeric|min:0"
            v-slot="{ errors }"
          >
            <v-text-field
              type="number"
              label="Posición del periodo"
              hint="Posición del periodo"
              v-model="displayPeriod.order"
              autofocus
              dense
              outlined
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="6" md="6">
          <v-switch
            v-model="displayPeriod.active"
            color="success"
            label="Activo"
          />
        </v-col>
      </v-row>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import DisplayPeriodService from '@/services/displayPeriodService'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      sections: [],
      items: [],
      displayPeriod: { order: 0 }
    }
  },
  async created() {
    this.loading = true
    try {
      const id = this.$route.params.id
      if (id) this.displayPeriod = await DisplayPeriodService.find(id)
    } catch (error) {
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await DisplayPeriodService.save(this.displayPeriod)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    }
  }
}
</script>
